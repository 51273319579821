export const config = {
// ------------------- Local -------------------
// "mongo_endpoint" : "http://localhost:5013/",
// "face_match_api": "http://3.108.132.228:5010",
// "imagePath":"http://localhost:5013/",

// ------------------- aes visitor pro demo server-------------------
"mongo_endpoint" : "https://vms.aesproducts.com/vmsapi",
"face_match_api": "https://vms.aesproducts.com/findface",
"imagePath":"https://vms.aesproducts.com/vmsapi",

// ------------------- Live --------------------
//  "mongo_endpoint" : "https://visitpro.acvhrms.com:8443/visitor/",
//  "face_match_api": "https://visitpro.acvhrms.com:8443/facematch",
//  "imagePath" : "https://visitpro.acvhrms.com:8443/visitor/",

"secret_key":"xrNCa3I2sHkR6Ha7ApB6H4NvVbJB8Oas1ZGrYVfbYZQ=",
}